import { image } from '@/theme';
import { FILE_DOWNLOAD_API_URL } from '@/api/apiUrls';

export const clearWebStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const addCommaToNumber = (number) => {
  if (number === undefined) return '';

  const strNumber = String(number);

  const parts = strNumber.split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export const removeCommaFromNumber = (number) => {
  if (number === null) {
    return null;
  }
  return number.replaceAll(',', '');
};

export const getLogoSrc = (logo, def) =>
  logo?.file_path && logo?.file_path !== 'file_path'
    ? `${FILE_DOWNLOAD_API_URL}?path=${logo.file_path}&tname=${logo.temp_file_name}&name=${logo.origin_file_name}`
    : // `http://3.39.97.146/storage/image/${logo.file_path}/${logo.temp_file_name}`
      def || image.logo_default;

export const convertTimestampToUTC = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  return [year, month, day];
};

export const handleImageError = (fallbackUrl) => (e) => {
  e.target.onerror = null;
  e.target.src = fallbackUrl;
};

export const filterParamCodeValues = (param, codeList) => {
  const paramSet = new Set(param?.split('|'));

  return codeList.reduce((acc, val) => {
    if (paramSet.has(val.code_name)) {
      acc.push(val.code_value);
    }
    return acc;
  }, []);
};

export const splitByAmpersand = (string) => {
  const parts = string.split('&').map((part) => part.trim());

  return parts.map((part, index) => {
    if (index === 0) return part;
    return '& ' + part;
  });
};

export const resizeImage = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = (e) => {
    const img = new Image();
    img.src = e.target.result;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const MAX_WIDTH = 800;
      const scaleSize = MAX_WIDTH / img.width;
      canvas.width = MAX_WIDTH;
      canvas.height = img.height * scaleSize;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob) => {
          callback(new File([blob], file.name, { type: file.type }));
        },
        file.type,
        1,
      );
    };
  };
};
