import React, { useState } from 'react';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { FOOTER_ZINDEX } from '@/constants/zIndex';
import { TitleText } from '../text';
import { Modal } from '../modal';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  width: '100%',
  height: '8rem',
  padding: '1rem',

  background: theme.color.base_white,
  zIndex: FOOTER_ZINDEX,
}));

const Line = styled.span(({ theme }) => ({
  width: '0.2rem',
  height: '1.8rem',
  background: theme.color.base_black,
}));

const TermsText = styled(({ ...props }) => <TitleText {...props} />)(() => ({
  cursor: 'pointer',
}));

const TermsModalContent = styled.div(() => ({
  width: '80rem',
  height: '60rem',
  padding: '1.6rem',
}));

const AboutusText = styled(({ ...props }) => <TitleText {...props} />)(() => ({
  cursor: 'pointer',
}));

const Footer = () => {
  const theme = useTheme();

  const [termsModal, setTermsModal] = useState(false);

  const privacyPolicy = () => ({
    __html: `<iframe src="/privacy-policy.html" title="privacy policy" width="100%" height="100%" scro></iframe>`,
  });

  return (
    <>
      <Container>
        <TitleText size="rmd" color={theme.color.base_black}>
          Copyright @the inventionlab, 2023. All Rights Reserved
        </TitleText>

        <Line />

        <TermsText size="bmd" color={theme.color.light500} onClick={() => setTermsModal(true)}>
          Terms & Policy
        </TermsText>

        <Line />

        <AboutusText size="bmd" color={theme.color.light500}>
          <a href="https://theilab.kr/?page_id=6495&lang=en" target="_blank" rel="noreferrer noopener">
            About Us
          </a>
        </AboutusText>
      </Container>

      <Modal isOpen={termsModal} setIsOpen={setTermsModal}>
        <TermsModalContent dangerouslySetInnerHTML={privacyPolicy()} />
      </Modal>
    </>
  );
};

export default Footer;
